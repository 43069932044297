import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import MetroFares from "../components/metro-fares"

class S62ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="S62"
          description="View route information and buy your ticket for the S62 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">S62 service</h1>
              <p>Cottingley Vale - Cockburn School / Laurence Calvert Academy Cockburn</p>
              <p>Cockburn School / Laurence Calvert Academy – Cottingley Vale</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="S62 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1n1nbvCt0Jv1oj6gQ3v6VpXMFwengbmxS"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Cockburn School/Laurence Calvert Academy
                </p>
                <p>
                From Cottingley Vale via Cottingley Drive, Ring Road Beeston, Elland Road, Wesley
                Street, Town Street, Beeston Road, Old Lane, Ring Road Beeston Park - (set down
                at Tommy Wass junction bus stop outside shops for Cockburn School) – Ring
                Road Middleton, Middleton Leisure Centre access Road.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="S62 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=14rLdJdblV13HyMscucS2Tnr5eNqUZ2o&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Laurence Calvert Academy/Cockburn School
                </p>
                <p>
                From Middleton Leisure Centre via access road, Ring Road Middleton, Gipsy Lane -
                (Cockburn School) – Gypsy Lane, Ring Road Beeston Park, Old Lane, Town Street,
                Wesley Street, Elland Road, Ring Road Beeston, Cottingley Drive and Cottingley
                Vale.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cottingley Vale</td>
                      <td>DEP</td>
                      <td>0750</td>
                    </tr>
                    <tr>
                      <td>Elland Road</td>
                      <td>DEP</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                    <td>Cockburn High School (Set down at Tommy Wass junction bus stop)</td>
                    <td>DEP</td>
                    <td>0805</td>
                  </tr>
                    <tr>
                      <td>Laurence Calvert Academy (Middleton Leisure Centre)</td>
                      <td>ARR</td>
                      <td>0815</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Laurence Calvert Academy (Middleton Leisure Centre)</td>
                      <td>DEP</td>
                      <td>1500</td>
                    </tr>
                    <tr>
                      <td>Cockburn School (pick up Gypsy Lane at school)</td>
                      <td>DEP</td>
                      <td>1510</td>
                    </tr>
                    <tr>
                      <td>Elland Road</td>
                      <td>DEP</td>
                      <td>1520</td>
                    </tr>
                    <tr>
                      <td>Cottingley Vale</td>
                      <td>ARR</td>
                      <td>1525</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default S62ServicePage
